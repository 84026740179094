.videoPlayer {
  position: relative;
  height: 0;
  width: 100%;
  background-color: var(--theme-elevation-0);
  overflow: hidden;
  margin-bottom: var(--base);
}

.iframe {
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}
